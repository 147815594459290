import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';

import { firstValueFrom } from 'rxjs';

import { provideGoogleAnalytics, provideGoogleAnalyticsRouter } from '@hakimio/ngx-google-analytics';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { provideL10nIntl, provideL10nTranslation } from 'angular-l10n';
import { provideToastr, ToastNoAnimation } from 'ngx-toastr';
import { AppStorage, HttpTranslationLoader, l10nConfig } from './l10n-config';

import { routes } from './app.routes';
import { OktaAuthenticateService } from './shared/services/okta-authenticate.service';

function initAuth(oktaAuth: OktaAuthenticateService, router: Router) {
  return () => {
    const promise = Promise;

    return firstValueFrom(oktaAuth.login())
      .then((response: any) => {
        if (response.redirectUrl === null) {
          const storedUrl = localStorage.getItem('tempUrl');

          if (storedUrl) {
            const temp: any = storedUrl.split('#');

            const tempUrl = temp[0];
            const tempFragment = temp[1] || '';

            if (tempUrl !== '/') {
              if (tempFragment) {
                router.navigate([tempUrl], { fragment: tempFragment });
              } else {
                router.navigate([tempUrl]);
              }
            }

            localStorage.removeItem('tempUrl');
          }
        }

        promise.resolve();
      })
      .catch((error) => {
        // save url to redirect after login
        localStorage.setItem('tempUrl', `${window.location.pathname}${window.location.hash}`);

        // handle rdirect url
        window.location.href = error.error.redirectUrl;

        // reject promise to prevent app from loading
        return promise.reject();
      });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),

    // ngx-loading-bar
    provideLoadingBarInterceptor(),
    provideLoadingBarRouter(),

    // google analytics
    provideGoogleAnalytics('G-WB829N07E8'),
    provideGoogleAnalyticsRouter(),
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [OktaAuthenticateService, Router],
      multi: true,
    },

    // angular-l10n
    provideL10nTranslation(l10nConfig, {
      storage: AppStorage,
      translationLoader: HttpTranslationLoader,
    }),
    provideL10nIntl(),

    // ngx-toastr
    provideToastr({
      toastComponent: ToastNoAnimation,
    }),
  ],
};
