import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

export type PbdsTheme = 'classic' | 'ocean' | 'shipaccel';

export type PbdsThemeMode = 'auto' | 'light' | 'dark';

export const PBDS_THEMES = [
  {
    label: 'Classic',
    value: 'classic',
  },

  {
    label: 'Ocean',
    value: 'ocean',
  },
];

export const PBDS_THEME_MODES = [
  {
    label: 'Auto',
    value: 'auto',
  },
  {
    label: 'Light',
    value: 'light',
  },
  {
    label: 'Dark',
    value: 'dark',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  document = inject(DOCUMENT);
  mediaMatcher = inject(MediaMatcher);

  link: HTMLLinkElement = this.document.getElementById('pbds-theme') as HTMLLinkElement;

  constructor() {
    // set the initial theme
    this.setTheme();

    // remove old saved theme
    localStorage.removeItem('theme_selection');

    // watch for dark mode changes
    this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      this.setTheme();
    });
  }

  get theme(): PbdsTheme {
    const theme = localStorage.getItem('pbds-theme') as PbdsTheme;

    if (theme === 'shipaccel') {
      return 'classic';
    }

    return theme || 'classic';
  }

  set theme(theme: PbdsTheme) {
    localStorage.setItem('pbds-theme', theme);
  }

  get mode(): PbdsThemeMode {
    return (localStorage.getItem('pbds-theme-mode') as PbdsThemeMode) || 'auto';
  }

  set mode(mode: PbdsThemeMode) {
    localStorage.setItem('pbds-theme-mode', mode);
  }

  setTheme(theme = this.theme, mode = this.mode): void {
    this.theme = theme;
    this.mode = mode;

    if (mode && mode === 'auto') {
      mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    this.link.setAttribute('href', `pbds-theme-${theme}-${mode}.css`);
  }

  removeSavedTheme(): void {
    localStorage.removeItem('pbds-theme');
    localStorage.removeItem('pbds-theme-mode');
  }
}
