import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';

import { NgxLoadingBar } from '@ngx-loading-bar/core';

import { Globals } from '@app/globals';
import { DemoService } from '@app/shared/services/demo.service';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NgxLoadingBar],
})
export class AppComponent implements OnInit {
  @HostBinding('class.demo') isDemo: boolean;

  @HostBinding('class.has-global-alert')
  get hasGlobalAlert(): boolean {
    return this.globals.hasGlobalAlert;
  }

  document = inject(DOCUMENT);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  titleService = inject(Title);
  scroller = inject(ViewportScroller);
  demoService = inject(DemoService);
  globals = inject(Globals);
  themeService = inject(ThemeService);

  constructor() {
    // scroll to window top on route change
    this.router.events.subscribe((event) => {
      let start = {
        url: '',
        fragment: '',
      };
      let end = {
        url: '',
        fragment: '',
      };

      function splitUrl(path) {
        path = path.split('#');
        return {
          url: path[0] || '',
          fragment: path[1] || '',
        };
      }

      if (event instanceof NavigationStart) {
        start = splitUrl(this.router.routerState.snapshot.url);
      } else if (event instanceof NavigationEnd) {
        end = splitUrl(this.router.routerState.snapshot.url);
      }

      if (start.url !== end.url && !end.fragment && !start.fragment) {
        this.scroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit() {
    // check if this is a demo
    this.demoService.currentMessage.subscribe((res) => (this.isDemo = res));

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => this.titleService.setTitle(event['title'] || 'Design System'));
  }
}
